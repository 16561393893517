import React from "react";
import { useTranslation } from "react-i18next";
import { i18n } from "i18next";

interface LanguageSelectorProps {
  i18n: i18n;
}

interface Language {
  code: string;
  lang: string;
}

const languages: Language[] = [
  { code: "en", lang: "EN" },
  { code: "uk", lang: "UA" },
];

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ i18n }) => {
  const { t } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    // window.location.reload();
  };

  return (
    <>
      {languages.map((lng) => (
        <h2
          className={lng.code === i18n.language ? "selected" : ""}
          key={lng.code}
          onClick={() => changeLanguage(lng.code)}
        >
          {lng.lang}
        </h2>
      ))}
    </>
  );
};

export default LanguageSelector;
