import "../styles/RunningLine.scss";
import { useTranslation } from "react-i18next";

const RunningLine = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="run">
        <div className="run__line">
          <div className="run__list">
            <div className="run__slide">
              <h3>{t("runS1T")}</h3>
              <p>
                {t("runS1D.line1")}
                <br />
                {t("runS1D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS2T")}</h3>
              <p>
                {t("runS2D.line1")}
                <br />
                {t("runS2D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS3T")}</h3>
              <p>
                {t("runS3D.line1")}
                <br />
                {t("runS3D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS4T")}</h3>
              <p>
                {t("runS4D.line1")}
                <br />
                {t("runS4D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS5T")}</h3>
              <p>
                {t("runS5D.line1")}
                <br />
                {t("runS5D.line2")}
              </p>
            </div>
          </div>
          <div className="run__list">
            <div className="run__slide">
              <h3>{t("runS1T")}</h3>
              <p>
                {t("runS1D.line1")}
                <br />
                {t("runS1D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS2T")}</h3>
              <p>
                {t("runS2D.line1")}
                <br />
                {t("runS2D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS3T")}</h3>
              <p>
                {t("runS3D.line1")}
                <br />
                {t("runS3D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS4T")}</h3>
              <p>
                {t("runS4D.line1")}
                <br />
                {t("runS4D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS5T")}</h3>
              <p>
                {t("runS5D.line1")}
                <br />
                {t("runS5D.line2")}
              </p>
            </div>
          </div>
          <div className="run__list">
            <div className="run__slide">
              <h3>{t("runS1T")}</h3>
              <p>
                {t("runS1D.line1")}
                <br />
                {t("runS1D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS2T")}</h3>
              <p>
                {t("runS2D.line1")}
                <br />
                {t("runS2D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS3T")}</h3>
              <p>
                {t("runS3D.line1")}
                <br />
                {t("runS3D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS4T")}</h3>
              <p>
                {t("runS4D.line1")}
                <br />
                {t("runS4D.line2")}
              </p>
            </div>
            <div className="run__slide">
              <h3>{t("runS5T")}</h3>
              <p>
                {t("runS5D.line1")}
                <br />
                {t("runS5D.line2")}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RunningLine;
