const TelegramWhite = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_191_76)">
        <path
          d="M12 24C5.383 24 0 18.617 0 12C0 5.383 5.383 0 12 0C18.617 0 24 5.383 24 12C24 18.617 18.617 24 12 24ZM12 1C5.935 1 1 5.935 1 12C1 18.065 5.935 23 12 23C18.065 23 23 18.065 23 12C23 5.935 18.065 1 12 1Z"
          fill="white"
        />
        <path
          d="M9.5 18C9.436 18 9.37 17.988 9.309 17.962C9.122 17.885 9 17.702 9 17.5V14.5C9 14.367 9.053 14.24 9.146 14.146L11.506 11.786L7.747 13.933C7.586 14.025 7.388 14.02 7.23 13.92L4.23 12.007C4.071 11.906 3.983 11.724 4.001 11.537C4.02 11.35 4.142 11.189 4.316 11.12L17.316 6.03296C17.487 5.96896 17.676 5.99796 17.816 6.11296C17.957 6.22796 18.023 6.40996 17.991 6.58896L15.991 17.589C15.963 17.744 15.863 17.877 15.722 17.947C15.581 18.017 15.415 18.017 15.275 17.947L11.597 16.108L9.852 17.853C9.758 17.949 9.63 18 9.5 18ZM11.5 15C11.576 15 11.652 15.017 11.724 15.053L15.127 16.755L16.848 7.29196L5.598 11.694L7.515 12.916L14.252 9.06596C14.47 8.94296 14.746 8.99796 14.898 9.19596C15.05 9.39496 15.031 9.67596 14.854 9.85296L10 14.707V16.293L11.146 15.147C11.242 15.05 11.37 15 11.5 15Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_191_76">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TelegramWhite;
