import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Header.scss";
import MenuIcon from "../assets/icons/MenuIcon";
import CrossIcon from "../assets/icons/CrossIcon";
import LanguageSelector from "./ui/LanguageSelector";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [modal, setModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 1320;
  const isLangHide = windowWidth <= 500;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showModal = () => {
    setModal(!modal);
    const modalEl = document.querySelector(".Modal") as HTMLElement;
    if (modalEl && modal === false) {
      modalEl.classList.add("show");
    }
    if (modalEl && modal === true) {
      modalEl.classList.remove("show");
    }
  };

  return (
    <header className="header">
      <div className="headerBody">
        <div className="headerNav">
          <Link to={"/#home"}>
            <img src={process.env.PUBLIC_URL + "logo.svg"} alt="" className="headerLogo" />
          </Link>
          {isMobile ? (
            <></>
          ) : (
            <>
              <Link to={"/#home"}>
                <button className="headerNavItem">{t("home")}</button>
              </Link>
              <Link to={"/#services"}>
                <button className="headerNavItem">{t("services")}</button>
              </Link>
              <Link to={"/#portfolio"}>
                <button className="headerNavItem">{t("portfolio")}</button>
              </Link>
              <Link to={"/#development"}>
                <button className="headerNavItem">{t("development")}</button>
              </Link>
              <Link to={"/#feedback"}>
                <button className="headerNavItem">{t("feedback")}</button>
              </Link>
            </>
          )}
        </div>
        {isMobile ? (
          <div className="headerMobile">
            <button className="MenuButton" onClick={showModal}>
              <MenuIcon></MenuIcon>
            </button>
            <button className="headerLang">
              <LanguageSelector i18n={i18n} />
            </button>
            <div className="Modal">
              <div className="ModalHead">
                <button className="CloseButton" onClick={showModal}>
                  <CrossIcon></CrossIcon>
                </button>
              </div>
              <div className="ModalBody">
                <Link to={"/#home"}>
                  <button className="modalNavItem">{t("home")}</button>
                </Link>
                <Link to={"/#services"}>
                  <button className="modalNavItem">{t("services")}</button>
                </Link>
                <Link to={"/#portfolio"}>
                  <button className="modalNavItem">{t("portfolio")}</button>
                </Link>
                <Link to={"/#development"}>
                  <button className="modalNavItem">{t("development")}</button>
                </Link>
                <Link to={"/#feedback"}>
                  <button className="modalNavItem">{t("feedback")}</button>
                </Link>
              </div>
              <div className="ModalBodyContacts">
                <div className="HeaderInfoSection">
                  <a
                    className="HeaderLocation"
                    href="https://maps.app.goo.gl/RVDPfAsH9FprGBq89"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h2 className="LocationCity">{t("footerCountry")}</h2>
                  </a>
                  <a
                    className="HeaderAddress"
                    href="https://maps.app.goo.gl/RVDPfAsH9FprGBq89"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("footerStreet.line1")}
                    <br />
                    {t("footerStreet.line2")}
                  </a>
                  <a className="HeaderPhoneNumber" href="tel:+380673694499">
                    067 369 44 99
                  </a>
                  <a className="HeaderPhoneNumber" href="tel:+380933694499">
                    093 369 44 99
                  </a>
                  <a className="HeaderPhoneNumber" href="tel:+380993694499">
                    099 369 44 99
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <button className="headerLang">
              {/* <img src={process.env.PUBLIC_URL + "/lang.svg"} alt="" /> */}
              <LanguageSelector i18n={i18n} />
            </button>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
