const FacebookWhite = () => {
  return (
    <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path 
            d="M11.998 0C5.38355 0 0 5.42093 0 12.0813C0 18.0102 4.21322 23.0207 10.0165 23.9959C10.0367 24 10.0609 24 10.081 24C10.1779 24 10.2667 23.9675 10.3434 23.9025C10.4322 23.8253 10.4846 23.7115 10.4846 23.5936V15.4013C10.4846 15.1778 10.303 14.9949 10.081 14.9949H7.55877V12.4876H10.081C10.303 12.4876 10.4846 12.3048 10.4846 12.0813V9.0701C10.4846 6.96512 12.1877 5.25025 14.2822 5.25025H16.5502C16.8085 5.25025 17.0183 5.46156 17.0183 5.72164V7.80224H15.5494C14.5808 7.80224 13.7898 8.59465 13.7898 9.57399V12.0813C13.7898 12.3048 13.9714 12.4876 14.1934 12.4876H16.9417L16.5018 14.9949H14.1853C13.9633 14.9949 13.7817 15.1778 13.7817 15.4013L13.7575 23.5489C13.7575 23.6708 13.81 23.7846 13.9028 23.8618C13.9956 23.939 14.1167 23.9716 14.2378 23.9472C19.8957 22.8744 24 17.8801 24 12.0772C23.996 5.42093 18.6124 0 11.998 0ZM14.5647 23.0491L14.5889 15.8077H16.8408C17.0385 15.8077 17.204 15.6654 17.2403 15.4704L17.8214 12.1504C17.8416 12.0325 17.8093 11.9106 17.7326 11.8171C17.656 11.7237 17.543 11.6708 17.4219 11.6708H14.5969V9.56993C14.5969 9.04165 15.0247 8.61091 15.5494 8.61091H17.4219C17.6439 8.61091 17.8255 8.42804 17.8255 8.20454V5.71758C17.8255 5.0105 17.2524 4.43346 16.5502 4.43346H14.2822C11.7437 4.43346 9.67748 6.50999 9.67748 9.06604V11.6708H7.1552C6.93324 11.6708 6.75164 11.8537 6.75164 12.0772V15.3972C6.75164 15.6207 6.93324 15.8036 7.1552 15.8036H9.67748V23.1019C4.50782 22.0129 0.80713 17.4534 0.80713 12.0813C0.80713 5.86793 5.82748 0.812733 11.998 0.812733C18.1685 0.812733 23.1888 5.86793 23.1888 12.0813C23.1888 17.3275 19.589 21.8625 14.5647 23.0491Z" 
            fill="white"
        />
    </svg>
  );
};

export default FacebookWhite;