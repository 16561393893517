import "../styles/Layout.scss";

const Layout = ({ children, title, type, text }: any) => {
  return (
    <div className="Layout">
      <div className="LayoutStatic">
        <div className="LayoutTitle">{title}</div>
        <div className="LayoutText">
          <h1 className="LayoutTechStack">{type}</h1>
          <span className="udertitleLayoutSpan"></span>
        </div>
        <p className="LayoutDescription">{text}</p>
      </div>

      <div className="LayoutBody">{children}</div>
    </div>
  );
};

export default Layout;
