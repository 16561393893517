const PhoneIcon = () => {
    return (
        <svg 
            viewBox="0 0 500 499" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M249.5 139C316.5 140 366 201 362.5 251.5M249.5 76.5C343 76.5 426.5 150.5 426.5 251.5M249.5 12C368.5 12 490 104.5 490 251.5M136.5 12C140.5 23.5 164.5 124.5 162.501 127.5C160.501 130.5 117.501 176.499 115.001 180.5C112.5 184.5 148.932 255.739 201.5 306C251.419 353.728 311.501 386.5 322.001 384.5C332.5 382.5 368 341.5 373.501 339C379.001 336.5 479.001 360.5 487.001 363.5C495 366.5 470 473.5 392 486.5C314 499.5 223.977 450.091 140 368.5C55.5944 286.493 10 205 10.0001 122C10.0002 38.9999 132.5 0.499994 136.5 12Z" 
                stroke="white" 
                strokeWidth="20" 
                strokeLinecap="round"/>
        </svg>
    );
};

export default PhoneIcon;