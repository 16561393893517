import "../styles/Main.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Modal } from "./ui/Modal";
import LightningAnimation from "./ui/LightningCanvas";

const Main = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && (
        <div className="modalContent">
          <Modal toggleModal={toggleModal} typeFromBody={"Лендінг"} />
          <div className="modalBg" onClick={toggleModal}></div>
        </div>
      )}
      <div className="Main" id="home">
        <div className="relative mx-auto py-20 md:py-40 px-4 w-full  left-0 top-0 textSection">
          <h1 className="MainTitle">
            {t("mainTitleUp")} <br /> {t("mainTitleDown")}
          </h1>
          <span className="udertitleSpan"></span>
          <div className="button-wrapper" style={{ marginTop: "100px" }}>
            <button className="custom-button" type="button" onClick={toggleModal}>
              <span className="b1"></span>
              <span className="b2"></span>
              <span className="b3"></span>
              <span className="b4"></span>

              <strong>{t("mainBtn")}</strong>
              <LightningAnimation />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
