import { useTranslation } from "react-i18next";
import PaperClipIcon from "../assets/icons/PaperClipIcon";
import "../styles/Footer.scss";
import Layout from "./Layout";
import { useRef, useState } from "react";
import WhatsappWhite from "../assets/icons/WhatsappWhite";
import FacebookWhite from "../assets/icons/FacebookWhite";
import InstWhite from "../assets/icons/InstWhite";
import TelegramWhite from "../assets/icons/TelegramWhite";
import { sendFile, sendMessage } from "../api/telegram";
import { message } from "antd";
import { useMask } from "@react-input/mask";
import { getFormSubmissionCount, incrementFormSubmissionCount } from "../utils/localStorageHelper";
import ViberWhite from "../assets/icons/ViberWhite";

const messages = [
  {
    messageSuccessUk: "Ваше повідомлення успішно надіслано!",
    messageSuccessEn: "Your message was sent successfully!",
  },
  {
    messageErrorUk: "Заповніть усі поля!",
    messageErrorEn: "Fill in all fields!",
  },
  {
    messageLimitUk: "Ви досягли максимальної кількості заявок.",
    messageLimitEn: "You have reached the maximum number of submissions.",
  },
];

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const inputRef = useMask({ mask: "(__)-___-__-_____", replacement: { _: /\d/ } });

  const messageSuccess = () => {
    messageApi.open({
      type: "success",
      content: i18n.language === "uk" ? messages[0].messageSuccessUk : messages[0].messageSuccessEn,
      className: "Message",
    });
  };

  const messageError = () => {
    messageApi.open({
      type: "error",
      // duration: 100,
      content: i18n.language === "uk" ? messages[1].messageErrorUk : messages[1].messageErrorEn,
      className: "Message",
    });
  };

  //////////// start

  const messageLimit = () => {
    messageApi.open({
      type: "warning",
      content: i18n.language === "uk" ? messages[2].messageLimitUk : messages[2].messageLimitEn,
      className: "Message",
    });
  };

  /////////////// end

  const [selectedFileName, setSelectedFileName] = useState<string | null>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    text: "",
    file: null,
  });

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
      setSelectedFileName(files[0].name);
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    ///////////// start

    const submissionCount = getFormSubmissionCount();

    if (submissionCount >= 3) {
      messageLimit();
      return;
    }

    ///////////// end

    try {
      setIsLoading(true);
      if (formValues.name && formValues.phone && formValues.text) {
        console.log(formValues.phone);
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone} %0A- Текст: ${formValues.text}`;

        await sendMessage(message);
        messageSuccess();

        //////////// start

        incrementFormSubmissionCount();

        //////////// end
      } else {
        messageError();
      }

      if (formValues.file) {
        await sendFile(formValues.file);
      }
    } catch (e) {
      console.log("Error");
    } finally {
      setIsLoading(false);
    }
  };

  function CurrentYear() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    return <span>{currentYear}</span>;
  }

  return (
    <section className="footer" id="feedback">
      {contextHolder}
      <Layout title={t("footerTitle")} type={t("footerType")}>
        <div className="footer__block">
          <div className="footer__content">
            <div className="footer__input-section">
              <form onSubmit={handleSubmit}>
                <div className="footer__small-inputs">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder={t("footerInputName")}
                    className="footer__input _small"
                    value={formValues.name}
                    onChange={handleChange}
                  />
                  <div className="footer__input phone_container">
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      ref={inputRef}
                      placeholder="(99)-999-99-99"
                      className="footer__input _small_phone"
                      value={formValues.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <input
                  id="projectDescription"
                  name="text"
                  type="text"
                  placeholder={t("footerInputText")}
                  className="footer__input _big"
                  value={formValues.text}
                  onChange={handleChange}
                />
                <div className="footer__buttons">
                  <div className="footer__attach">
                    <input
                      id="file"
                      name="file"
                      type="file"
                      accept=".pdf, .doc, .docx, .svg, .webp, .png, .jpg, .jpeg, .HEIC"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleChange}
                    />
                    <button type="button" onClick={handleFileUpload}>
                      <PaperClipIcon />
                    </button>
                    <h4>{selectedFileName ? selectedFileName : t("footerFile")}</h4>
                  </div>
                  <button type="submit" className="footer__button-submit">
                    {t("footerBtn")}
                  </button>
                </div>
              </form>
            </div>
            <div className="footer__info-section">
              <a
                className="footer__location"
                href="https://maps.app.goo.gl/RVDPfAsH9FprGBq89"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="footer__location-city">
                  {t("footerCountry")}
                  <br />
                  <span>
                    {t("footerStreet.line1")}
                    <br />
                    {t("footerStreet.line2")}
                  </span>
                </h2>
              </a>
              <a className="footer__phone-number" href="tel:+380673694499">
                067 369 44 99
              </a>
              <a className="footer__phone-number" href="tel:+380933694499">
                093 369 44 99
              </a>
              <a className="footer__phone-number" href="tel:+380993694499">
                099 369 44 99
              </a>
              <a className="footer__info-section__email" href="mailto:abcdigital.sale@gmail.com">
                ABCDIGITAL.sale@gmail.com
              </a>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="footer-copyright__socials-list">
              <a href="https://wa.me/+380673694499">
                <WhatsappWhite />
              </a>
              <a href="https://t.me/abcd_expert">
                <TelegramWhite />
              </a>
              <a href="viber://chat?number=%2B380673694499">
                <ViberWhite />
              </a>
              <a href="https://www.facebook.com/share/5xxkz8hBz8WyREjU/?mibextid=LQQJ4d">
                <FacebookWhite />
              </a>
              <a href="https://www.instagram.com/abcdigital.ua?igsh=OWE2Nm12N2x5Ym9o&utm_source=qr">
                <InstWhite />
              </a>
            </div>
            <div className="footer-copyright__copyright">
              <span className="copyright">
                <CurrentYear /> © ABC Digital
              </span>
            </div>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default Footer;
