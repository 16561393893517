const WhatsappWhite = () => {
  return (
    <svg 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_191_71)">
            <path 
                d="M12 0.500011C9.91442 0.50022 7.86816 1.06758 6.08024 2.14137C4.29233 3.21515 2.83008 4.75494 1.85002 6.59589C0.869957 8.43685 0.408977 10.5097 0.516425 12.5925C0.623873 14.6753 1.2957 16.6897 2.46 18.42L0.5 23.5L5.73 21.63C7.22993 22.6091 8.93783 23.2242 10.7176 23.4265C12.4973 23.6287 14.2997 23.4124 15.981 22.7948C17.6624 22.1772 19.1763 21.1754 20.4019 19.8692C21.6276 18.5631 22.5312 16.9886 23.0408 15.2715C23.5503 13.5543 23.6517 11.7418 23.3369 9.97849C23.022 8.21519 22.2996 6.54981 21.2272 5.11509C20.1549 3.68038 18.7622 2.51599 17.1602 1.71474C15.5582 0.913491 13.7912 0.49753 12 0.500011Z" 
                stroke="white" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M9.5 14.5C10.8 15.8 13.67 17.5 15 17.5C15.5834 17.506 16.151 17.3102 16.6066 16.9457C17.0622 16.5813 17.3778 16.0705 17.5 15.5V14.5C17.5 14.5 16.27 13.9 15.5 13.5C14.73 13.1 13.5 14.5 13.5 14.5C12.5641 14.1951 11.7094 13.6823 11 13C10.3177 12.2906 9.80488 11.4359 9.5 10.5C9.5 10.5 10.9 9.27 10.5 8.5C10.1 7.73 9.5 6.5 9.5 6.5H8.5C7.92949 6.62219 7.41874 6.93782 7.05426 7.39341C6.68979 7.84901 6.49398 8.41658 6.5 9C6.5 10.33 8.2 13.2 9.5 14.5Z" 
                stroke="white" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_191_71">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
};

export default WhatsappWhite;