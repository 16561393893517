import React, { useRef, useEffect } from "react";

const LightningAnimation: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (canvas && ctx) {
      const width = canvas.width;
      const height = canvas.height;

      ctx.clearRect(0, 0, width, height); // Очистка полотна перед кожним рендерингом

      const drawLightning = (startX: number, startY: number, endX: number, endY: number, segments: number) => {
        ctx.beginPath();
        ctx.moveTo(startX, startY);

        // Налаштування ефекту світіння
        ctx.shadowBlur = 10;
        ctx.shadowColor = "#bff4f3";
        ctx.strokeStyle = "#88f1f0";
        ctx.lineWidth = 2;

        for (let i = 0; i < segments; i++) {
          const segmentX = startX + (endX - startX) * (i / segments) + (Math.random() - 0.5) * 20;
          const segmentY = startY + (endY - startY) * (i / segments) + (Math.random() - 0.5) * 20;
          ctx.lineTo(segmentX, segmentY);
        }

        ctx.lineTo(endX, endY);
        // ctx.strokeStyle = '#88f1f0';
        // ctx.lineWidth = 2;
        ctx.stroke();
      };

      const animateLightning = () => {
        ctx.clearRect(0, 0, width, height); // Очистка полотна перед кожним рендерингом
        drawLightning(0, 40, 250, 40, 10); // Параметри блискавки (початкові та кінцеві координати)
        requestAnimationFrame(animateLightning); // Безкінечна анімація
      };

      animateLightning(); // Запуск анімації
    }
  }, []);

  return <canvas ref={canvasRef} width="250" height="80" />;
};

export default LightningAnimation;
