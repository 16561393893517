const PreLoader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="438.9383"
      height="126.1807"
      viewBox="0 0 438.9383 126.1807"
    >
      <g id="layer_1" data-name="Слой 1">
        <path
          transform="matrix(1,0,0,-1,1,125.1807)"
          d="M456.8105 16.96H313.4305V89.7712C313.4305 111.4977 328.9918 124.1807 350.0099 124.1807H457.1586V107.6193H355.0218C341.2925 107.6193 332.4934 100.6591 332.4934 86.43V33.6878H457.1586V16.96ZM102.6641 124.1807 102.6836 124.1674 50.26 55.6886H150.9038L172.8558 33.6878H257.1738C264.6714 33.6878 271.3521 38.177 271.3521 46.1971V49.6151C271.3521 53.1051 269.3974 56.3698 266.4694 58.7931 263.3973 61.3375 259.2607 62.9575 255.1697 62.9575H168.8613C160.0605 71.68 151.348 80.4986 142.5475 89.2225V124.1807H261.5145C269.9283 124.1807 276.9959 121.458 281.9934 116.9328 287.1278 112.2866 290.0803 105.7414 290.0803 98.2845V94.6984C290.0803 84.9929 283.7018 75.263 274.661 71.6406 278.6913 69.8899 282.81 66.5779 285.823 62.2128 288.5797 58.2168 290.4151 53.3322 290.4151 47.9463V43.6887C290.4151 36.392 287.1833 29.6881 281.9755 24.8233 276.8051 19.9877 269.68 16.96 261.8493 16.96L166.2579 17.0629 144.1117 39.2975H37.7079L20.6374 16.9991H0L82.0596 124.1807ZM256.8387 107.6193H161.7786V93.1893L175.2973 79.6853H254.6702C258.9389 79.6853 262.8649 81.3674 265.7801 83.8431 268.8911 86.4805 270.8493 90.0113 270.8493 93.3639V96.2807C270.8493 99.5094 269.3565 102.3361 266.8742 104.3563 264.3659 106.3962 260.8498 107.6193 256.8387 107.6193"
          fill="White"
        />
      </g>
    </svg>
  );
};

export default PreLoader;
