import Layout from "./Layout";
import "../styles/TimeLine.scss";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const TimeLine = () => {
  const { t, i18n } = useTranslation();

  const accordionItemRefs = useRef<HTMLDivElement[]>([]);

  const toggleAccordion = (index: number) => {
    if (accordionItemRefs.current[index]) {
      accordionItemRefs.current[index].classList.toggle("open");
    }
  };

  return (
    <div className="TimeLine">
      <Layout title={t("answers")} type={t("answersTitle")}>
        <main>
          <div className="accordion">
            {data.map((item, index) => (
              <div
                className="accordion-item"
                ref={(el) => (accordionItemRefs.current[item.id] = el as HTMLDivElement)}
                onClick={() => toggleAccordion(item.id)}
                key={index}
              >
                <div className="accordion-item-header">
                  <span className="accordion-item-header-title">
                    {i18n.language === "en" ? item.headerEn : item.headerUa}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-chevron-down accordion-item-header-icon"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </div>
                <div className="accordion-item-description-wrapper">
                  <div className="accordion-item-description">
                    <hr />
                    {i18n.language === "en" ? (
                      <div className="TimeLineData">
                        <p>{item.bodyEn}</p>
                      </div>
                    ) : (
                      <div className="TimeLineData">
                        <p>{item.bodyUa}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </Layout>
    </div>
  );
};

export default TimeLine;

const data = [
  {
    id: 0,
    headerEn: "How much does it cost to develop a website?",
    headerUa: "Скільки коштує розробити сайт?",
    bodyEn:
      "Website development depends on many factors, such as the number of pages, animations, and functionality to be implemented. Leave your feedback and we will help you find the best solution for your business and budget)",
    bodyUa:
      "Розробка сайту залежить від багатьох факторів, таких як к-сть сторінок, анімацій, функціоналу який повинен бути впроваджений. Залиште свій відгук, і ми допоможемо вам знайти найкраще рішення для вашого бізнесу та бюджету)",
  },
  {
    id: 1,
    headerEn: "WHY DO YOU NEED WEBSITE PROMOTION?",
    headerUa: "НАВІЩО ПОТРІБНО ПРОСУВАННЯ САЙТУ?",
    bodyEn:
      "To make your website famous among a large number of people on the Internet, you need to start promoting it. Let's say you've opened an online store to sell goods online. Now the question arises: “Who are your customers?”. It is thanks to promotion that you will be able to convey information about your products to those who are interested in purchasing them.",
    bodyUa:
      "Щоб ваш сайт став відомим серед великої кількості людей в інтернеті, необхідно зайнятися його просуванням. Припустимо, ви відкрили онлайн-магазин для продажу товарів через інтернет. Тепер виникає питання: “Хто ваші покупці?”. Саме завдяки просуванню ви зможете донести інформацію про вашу продукцію до тих, хто зацікавлений у її придбанні.",
  },
  {
    id: 2,
    headerEn: "What is better: a landing page or a multi-page website?",
    headerUa: "Що краще Landing page або багатосторінковий сайт?",
    bodyEn:
      "If you want to promote a specific product or service, it is advisable to create a landing page. This will allow you to focus all the user's attention on this product or service, which will increase the likelihood of the desired targeted action.",
    bodyUa:
      "Якщо ви хочете просувати конкретний товар або послугу, доцільно створити Landing page. Це дозволить сконцентрувати всю увагу користувача на даному товарі чи послузі, що збільшить ймовірність здійснення бажаної цільової дії.",
  },
  {
    id: 3,
    headerEn: "How long does it take to create a website?",
    headerUa: "Який термін створення веб-сайту?",
    bodyEn:
      "Depending on the volume. On average, the development of a quality product takes at least 1 month. For large pages, CRM systems, or Web services, the period may be longer",
    bodyUa:
      "В залежності від об'єму. В середньому, розробка якісного продукту займає не менше 1 місяця. Для великих сторінок, CRM-систем або Веб-сервісів термін може бути більшим.",
  },
  {
    id: 4,
    headerEn: "Do you use AI services in the process?",
    headerUa: "Чи використовуєте ви в процесі нейромережі?",
    bodyEn:
      "No, we don't. Each of our products is made by specialists on a turnkey basis. We embody any of your wishes in the form of a web application, rather than reworking someone else's material.",
    bodyUa:
      "Ні. Кожен наш продукт робиться спеціалістами під ключ. Ми втілюємо будь-які ваші побажання у вигляді веб-застосунку, а не перероблюємо чужий матеріал.",
  },
];
