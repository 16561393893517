import { useTranslation } from "react-i18next";
import "../styles/Portfolio.scss";
import Layout from "./Layout";
import { CardBody, CardContainer, CardItem } from "./ui/3d-card";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Portfolio = () => {
  const { t, i18n } = useTranslation();
  const [countOfCards, setCountOfCards] = useState(6);
  const [portfolioData, setPortfolioData] = useState(imagesUrl.slice(0, countOfCards));

  const increaseCards = () => {
    setCountOfCards(12);
    setPortfolioData(imagesUrl.slice(0, countOfCards + 6));
  };

  useEffect(() => {
    if (countOfCards >= imagesUrl.length) {
      const portfolioButton = document.querySelector(".PortfolioButton") as HTMLElement;
      portfolioButton.style.display = "none";
    }
  }, [countOfCards]);

  return (
    <div className="Portfolio" id="portfolio">
      <Layout title={t("projectsTitle")} type={t("projectsType")}>
        <div className="PortfolioCards">
          {portfolioData.map((el, index) => (
            <CardContainer key={index} className="">
              <CardBody className=" relative group/card bg-opacity-75  hover:shadow-2xl hover:shadow-emerald-500/[0.1] w-auto h-auto p-6 border-2 PortfolioCard">
                <CardItem translateZ="50" className="text-xl font-bold text-white">
                  {i18n.language === "en" ? el.titleEn : el.titleUa}
                </CardItem>
                <CardItem as="p" translateZ="60" className="text-sm max-w-sm mt-2 text-neutral-300 project-desc">
                  {i18n.language === "en" ? el.smallTitleEn : el.smallTitleUa}
                </CardItem>
                <CardItem translateZ="100" className="w-full mt-4">
                  <img
                    src={process.env.PUBLIC_URL + el.img}
                    height="1000"
                    width="1000"
                    className="h-60 w-full object-cover rounded-xl group-hover/card:shadow-xl CardImage"
                    alt="thumbnail"
                  />
                </CardItem>
                <div className="flex justify-between items-center mt-20 CardButton">
                  <CardItem
                    translateZ={20}
                    target="__blank"
                    className="px-4 py-2 rounded-xl text-xs font-normal text-white"
                  >
                    {""}
                  </CardItem>
                  <Link to={`/projects/${el.id}`} state={{ projectData: el }} key={index}>
                    <CardItem
                      translateZ={20}
                      as="button"
                      className="px-4 py-2 rounded-xl bg-white text-black text-xs font-bold"
                    >
                      {t("projectsCardBtn")}
                    </CardItem>
                  </Link>
                </div>
              </CardBody>
            </CardContainer>
          ))}
        </div>
        <button className="PortfolioButton" onClick={increaseCards}>
          {t("projectsBtn")}
        </button>
      </Layout>
    </div>
  );
};

export default Portfolio;

const imagesUrl = [
  {
    id: 0,
    link: "https://terragroup-ukraine.com/",
    img: "/portfolio/terra/terraPh1.webp",
    typeEn: "Landing",
    typeUa: "Лендінг",
    portfolioDetailImg: [
      "/portfolio/terra/terraPh1.webp",
      "/portfolio/terra/terraPh2.webp",
      "/portfolio/terra/terraPh3.webp",
    ],
    technologies: [0, 4, 5, 6, 9],
    titleEn: "Terra Group",
    titleUa: "Terra Group",
    smallTitleUa: "Сайт логістичної компанії по доставці зерна",
    smallTitleEn: "Website of a logistics company for grain delivery",
    descPrjctEn:
      "Terra Group is a Ukrainian company that has been operating since 2017, supplying grains and oilseeds from Ukrainian farmers to the domestic market of Ukraine and exporting to the markets of Europe, Asia, Africa and America.",
    descPrjctUa:
      "Terra Group - українська компанія, яка працює з 2017 року, поставляє зернові та олійні культури від українських фермерів на внутрішній ринок України та експортує на ринки Європи, Азії, Африки та Америки.",
    descSmallEn:
      "Our task was to produce a stylish, modern and unique design in a short time. In less than 2 weeks, we fully released this project, where all the customer's wishes were realized.",
    descSmallUa:
      "Наше завдання полягало у виготовленні стильного, сучасного та унікального дизайну у короткі сроки. Менше ніж за 2 тижні, ми повністю випустили цей проект, де були втілені всі побажання замовника.",
  },

  {
    id: 1,
    link: "https://buddesign-21.com/",
    img: "/portfolio/buddesign/buddPh2.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    portfolioDetailImg: [
      "/portfolio/buddesign/buddPh1.webp",
      "/portfolio/buddesign/buddPh2.webp",
      "/portfolio/buddesign/buddPh3.webp",
    ],
    technologies: [0, 4, 5, 6, 9],
    titleEn: "Buddesign-21",
    titleUa: "Buddesign-21",
    smallTitleUa: "Сайт будівельної компанії Buddesign-21",
    smallTitleEn: "Website of the building company",
    descPrjctEn:
      "Buddesign is a company with many years of experience, high-quality projects, a team of professionals, and modern equipment that is engaged in construction, supply of goods, and outsourcing throughout Ukraine.",
    descPrjctUa:
      "Buddesign - компанія з багаторічним досвідом, якісно виконаними проєктами, командою професіоналів та сучасною технікою, яка займається будівельними роботами, постачанням товарів та аутсорсингу по всій Україні.",
    descSmallEn:
      "Our task was to create a new website in a modern style. We implemented a solution that allows customers to edit content without creating a separate CRM system.",
    descSmallUa:
      "Наше завдання полягало у створені нового веб-сайту у сучасному стилі. Ми втілили у життя рішення, яке дозволяє клієнтам редагувати наповнення не створюючи при цьому окрему CRM-систему.",
  },

  {
    id: 2,
    link: "https://felec.com.ua/",
    img: "/portfolio/felec/felecPh1.webp",
    typeEn: "Landing",
    typeUa: "Лендінг",
    portfolioDetailImg: [
      "/portfolio/felec/felecPh1.webp",
      "/portfolio/felec/felecPh2.webp",
      "/portfolio/felec/felecPh3.webp",
    ],
    technologies: [2, 4, 5, 6, 7, 8],
    titleEn: "Felec",
    titleUa: "Felec",
    smallTitleUa: "Сайт з продажу батарей",
    smallTitleEn: "Website for selling batteries",
    descPrjctEn:
      "Felec is a supplier company that specializes in the sale of batteries, accumulators, solar panels and inverters that help to avoid power outages in Ukraine.",
    descPrjctUa:
      "Felec - це компанія-постачальник яка спеціалізується на продажі батарей, акумуляторів, сонячних батарей та інверторів, які допомагають уникнути відключень світла в Україні.",
    descSmallEn:
      "We developed a website for Felec to make it easier for them to present their products to their customers. The main condition was to quickly create a landing page that would help to collect taxes from Ukrainian customers.",
    descSmallUa:
      "Ми розробили сайт для компанії Felec, щоб їм було зручніше представляти свої товари для своїх клієнтів. Головною умовою було швидке створення Лендінгу, який допоможе збілшити подажі від Українських клієнтів.",
  },

  {
    id: 3,
    link: "https://shampuroff.com/",
    img: "/portfolio/shamp/shampPh1.webp",
    typeEn: "Web-shop",
    typeUa: "Інтернет-магазин",
    portfolioDetailImg: [
      "/portfolio/shamp/shampPh1.webp",
      "/portfolio/shamp/shampPh2.webp",
      "/portfolio/shamp/shampPh3.webp",
    ],
    technologies: [0, 1, 4, 5, 6, 7],
    titleEn: "ShampoorOff",
    titleUa: "ШампурOff",
    smallTitleUa: "Інтернет-магазин з продажу м'ясної продукції",
    smallTitleEn: "Online store selling meat products",
    descPrjctEn: "The shop specializing in making marinades and grilling meat and vegetables.",
    descPrjctUa: "Магазин, що спеціалізується на виготовленні маринадів та смаженні м'яса й овочів на грилі.",
    descSmallEn:
      "With the increase of the client base and daily orders - there was a need for a light and functional website with a personal account of the client and the introduction of delivery of products around the city.",
    descSmallUa:
      "Зі збільшенням клієнтської бази та щоденних замовлень - виникла потреба у легкому та функціональному веб-сайті з особистим кабінетом клієнта та впровадженням доставки продукції по місту.",
  },

  {
    id: 4,
    link: "https://shatskregion.in.ua/",
    img: "/portfolio/shatsk/shatPh1.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    portfolioDetailImg: [
      "/portfolio/shatsk/shatPh1.webp",
      "/portfolio/shatsk/shatPh2.webp",
      "/portfolio/shatsk/shatPh3.webp",
    ],
    technologies: [0, 4, 5, 6, 9],
    titleEn: "Shatskiy Kray",
    titleUa: "Шацький Край",
    smallTitleUa: "Веб-сайт про природу Шацького Краю",
    smallTitleEn: "Website about the nature of Shatsk region",
    descPrjctEn:
      "Shatsk region, the nature of 30 lakes invites you to unforgettable adventures in magical places! Nature, western lakes, delicious delicacies and exciting experiences are waiting for you!",
    descPrjctUa:
      "Шацький край, природа 30 озер запрошує вас до незабутніх пригод чарівними місцями! Природа, західні озера, смачні делікатеси та захопливі враження чекають на вас!",
    descSmallEn:
      "Our task was to create a customized concept for the tender. Since the site has a large number of photos, we decided to choose Firebase cloud technology to store all the data, which allowed us to optimize the site's performance.",
    descSmallUa:
      "Наше завдання полягало у створенні індивідуального концепту для тендеру. Оскільки сайт має велику кількість фотографій, нами було прийняте рішення обрати хмарну технологію Firebase для зберігання усіх даних, що дозволило оптимізувати роботу сайту.",
  },

  {
    id: 5,
    link: "https://banka-dikanka.kiev.ua/uk/",
    img: "/portfolio/spa/spaPh1.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    smallTitleUa: "Сайт Київської бані",
    smallTitleEn: "Kyiv bathhouse website",
    portfolioDetailImg: ["/portfolio/spa/spaPh1.webp", "/portfolio/spa/spaPh2.webp", "/portfolio/spa/spaPh3.webp"],
    technologies: [0, 4, 5, 6, 7, 8],
    titleEn: "Dikanka Bathhouse",
    titleUa: "Банька Диканька",
    descPrjctEn:
      "Sauna in the center of the city, provides halls for companies for different numbers of people, the range also includes related products.",
    descPrjctUa:
      "Сауна в центрі міста, предоставляє зали для компаній на різну кількість людей, в асортименті мають і супутні товари.",
    descSmallEn:
      '"The world is digitizing - it is our time for this too" - with these words the owner of the sauna addressed us, the task was to develop a simple landing page with a demonstration of the available services.',
    descSmallUa:
      '"Світ цифровізується - настав і наш час для цього" - з такими словами до нас звернувся власник сауни, завдання полягало у розробці простого лендінгу з демонстрацією наявних послуг.',
  },

  {
    id: 6,
    link: "https://cemastco.com/",
    img: "/portfolio/cemasto/cemastoPh1.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    portfolioDetailImg: [
      "/portfolio/cemasto/cemastoPh1.webp",
      "/portfolio/cemasto/cemastoPh2.webp",
      "/portfolio/cemasto/cemastoPh3.webp",
    ],
    technologies: [0, 1, 4, 5, 6, 7],
    titleEn: "Cemasto",
    titleUa: "Cemasto",
    smallTitleUa: "Сайт брокерської компанії морських та річкових суден",
    smallTitleEn: "Website of the brokerage company of sea vessels",
    descPrjctEn:
      "Cemasto is a leading brokerage company specializing in the purchase and sale of maritime and river vessels. The company has close ties with shipowners worldwide and provides clients with comprehensive support at all stages of the transaction.",
    descPrjctUa:
      "Cemasto є провідною брокерською компанією, що спеціалізується на купівлі-продажу морських та річкових суден. Компанія має тісні зв'язки з судновласниками по всьому світу і надає клієнтам повну підтримку на всіх етапах угоди.",
    descSmallEn:
      "Our task was to redesign the old website into a more modern version with enhanced functionality for clients, including the integration of new tools, an improved user interface, and optimized loading speeds.",
    descSmallUa:
      "Наше завдання полягало у переробці старого веб-сайту на більш сучасний варіант зі збільшеним функціоналом можливостей для клієнтів, включаючи інтеграцію нових інструментів, поліпшення користувацького інтерфейсу та оптимізацію швидкості завантаження.",
  },

  {
    id: 7,
    link: "https://trendcrm.biz/",
    img: "/portfolio/trendcrm/trendcrmPh2.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    portfolioDetailImg: [
      "/portfolio/trendcrm/trendcrmPh1.webp",
      "/portfolio/trendcrm/trendcrmPh2.webp",
      "/portfolio/trendcrm/trendcrmPh3.webp",
    ],
    technologies: [2, 4, 5, 6, 7, 8],
    titleEn: "Trend CRM",
    titleUa: "Trend CRM",
    smallTitleUa: "Сайт IT компанії по розробці CRM-систем",
    smallTitleEn: "Website of company for development CRM-systems",
    descPrjctEn:
      "Trend CRM specializes in developing CRM systems that optimize customer relationship management and enhance business efficiency. They offer tailored solutions that improve client interactions and stimulate business growth.",
    descPrjctUa:
      "Trend CRM спеціалізується на розробці CRM-систем, які оптимізують управління взаємовідносинами з клієнтами та підвищують ефективність бізнесу. Мають індивідуальні рішення, які покращують взаємодію з клієнтами та стимулюють зростання бізнесу.",
    descSmallEn:
      "We developed a website for Trend CRM to help them more effectively present their services to their clients. The new site features an intuitive design and enhanced functionality, making it easier for customers to explore and utilize their CRM solutions.",
    descSmallUa:
      "Ми розробили сайт для компанії Trend CRM, щоб їм було зручніше представляти свої послуги для своїх клієнтів. Новий сайт має інтуїтивно зрозумілий дизайн і розширений функціонал, що полегшує клієнтам ознайомлення та використання їхніх CRM-рішень.",
  },
  {
    id: 8,
    link: "https://packmeal.com.ua/",
    img: "/portfolio/meal/mealPh1.webp",
    typeEn: "Web-shop",
    typeUa: "Інтернет-магазин",
    smallTitleUa: "Інтернет-магазин однороазового посуду",
    smallTitleEn: "Online store of disposable tableware",
    portfolioDetailImg: [
      "/portfolio/meal/mealPh1.webp",
      "/portfolio/meal/mealPh2.webp",
      "/portfolio/meal/mealPh3.webp",
    ],
    technologies: [0, 1, 4, 5, 6, 7],
    titleEn: "Pack Meal",
    titleUa: "Pack Meal",
    descPrjctEn: "Production of disposable tableware for restaurants and cafes and family use.",
    descPrjctUa: "Виробництво одноразового посуду для закладів харчування та кав'ярень і сімейного використання.",
    descSmallEn:
      "Eco-friendly e-commerce website, intuitive for new customers, product catalog with filters and sorting options.",
    descSmallUa:
      "Екологічний e-commerce веб-сайт, інтуїтивно зрозумілий для нових клієнтів, каталог товарів з фільтрами та варіантами сортування.",
  },
  {
    id: 9,
    link: "https://rouges.com.ua/",
    img: "/portfolio/guns/gunsPh1.webp",
    typeEn: "Web-shop",
    typeUa: "Інтернет-магазин",
    smallTitleUa: "Інтернет-магазин мисливських пристосувань",
    smallTitleEn: "Online store of hunting equipment",
    portfolioDetailImg: [
      "/portfolio/guns/gunsPh1.webp",
      "/portfolio/guns/gunsPh2.webp",
      "/portfolio/guns/gunsPh3.webp",
    ],
    technologies: [2, 3, 5, 6, 7, 8],
    titleEn: "Rujes",
    titleUa: "Ружес",
    descPrjctEn:
      "Manufacturer of high-quality hunting and shooting equipment from cleaning weapons to equipping hunting cartridges.",
    descPrjctUa:
      "Виробник високоякісних мисливських та стрілецьких пристосувань від чистки зброї до спорядження мисливських патронів.",
    descSmallEn:
      "The task was to develop a site with a light hunting vibe, on which the company could best present its product to its own audience.",
    descSmallUa:
      "Завдання полягало у розробці сайту з легким мисливським вайбом, на якому компанія могла б якнайкраще представити свій товар власній аудиторії.",
  },
  {
    id: 10,
    img: "/portfolio/coin/coinPh2.webp",
    typeEn: "Web-shop",
    typeUa: "Інтернет-магазин",
    smallTitleUa: "Інтернет-магазин сувенірних нагород",
    smallTitleEn: "Online store of souvenir awards",
    portfolioDetailImg: [
      "/portfolio/coin/coinPh1.webp",
      "/portfolio/coin/coinPh2.webp",
      "/portfolio/coin/coinPh3.webp",
    ],
    technologies: [0, 1, 4, 5, 6, 7],
    titleEn: "Challange Coin",
    titleUa: "Challange Coin",
    descPrjctEn: "Manufacturer of souvenir and commemorative metal products, from valuable coins to military awards.",
    descPrjctUa: "Виробник сувенірної та пам'ятної продукції з металу, від коштовних монет до бойових нагород.",
    descSmallEn:
      "A portfolio site with the possibility of feedback, a description of the company with their achievements and a catalog of goods divided into categories.",
    descSmallUa:
      "Сайт-портфоліо з можливістю зворотного зв'язку, опис компанії з їхніми досягненнями і каталогом товарів, що ділиться на категорії.",
  },
  {
    id: 11,
    img: "/portfolio/drive/drivePh1.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    smallTitleUa: "Сайт Автошколи",
    smallTitleEn: "Driving school website",
    portfolioDetailImg: [
      "/portfolio/drive/drivePh1.webp",
      "/portfolio/drive/drivePh2.webp",
      "/portfolio/drive/drivePh3.webp",
    ],

    technologies: [0, 1, 4, 5, 6, 7],
    titleEn: "Let's Drive",
    titleUa: "Let's Drive",
    descPrjctEn:
      "A driving school with its own fleet of cars, motorcycles, and trucks, as well as training grounds for driving under various conditions and circumstances.",
    descPrjctUa:
      "Автошкола з власним парком легкових, мотоциклетних і вантажних автомобілів, а також полігонами для навчання водінню в різних умовах і обставинах.",
    descSmallEn:
      "Development of a website with the option to choose a course tariff, the cost of which varies depending on the desired number of practical lessons, choice of vehicle for training, and instructor, as well as the development of an admin panel with easy updating capabilities for changes in traffic regulations and posting of own blogs.",
    descSmallUa:
      "Розробка сайту з можливістю вибору тарифу курсу, вартість якого варіюється в залежності від бажаної кількості практичних занять, вибору транспорту для навчання та інструктора, а також розробка адмінпанелі з можливістю легкого оновлення для зміни ПДР та розміщення власних блогів.",
  },
  {
    id: 12,
    img: "/portfolio/repair/repairPh1.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    smallTitleUa: "Сайт будівельної компанії",
    smallTitleEn: "Website of a construction company",
    portfolioDetailImg: [
      "/portfolio/repair/repairPh1.webp",
      "/portfolio/repair/repairPh2.webp",
      "/portfolio/repair/repairPh3.webp",
    ],

    technologies: [0, 4, 5, 6, 7, 8],
    titleEn: "Buy Repair",
    titleUa: "Купи ремонт",
    descPrjctEn:
      "The company specializes in repairs of residential and commercial premises, providing a full range of services from design projects, rough and finishing works, to selection of decorations and furnishing of premises.",
    descPrjctUa:
      "Компанія займається ремонтами житлових та комерційних приміщень, надає усі види послуг - від дизайн-проєкту, чорнових та чистових робіт, до підбору декорацій та наповнення приміщень.",
    descSmallEn:
      "The task was to create a website with ready-made projects, featuring examples of accompanying documentation and descriptions of each stage of collaboration.",
    descSmallUa:
      "Завдання полягало у наповненому сайті готовими проєктами, з прикладами супровідної документації та описами кожної стадії співпраці.",
  },
  {
    id: 13,
    img: "/portfolio/krot/krotPh2.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    smallTitleUa: "Сайт водопостачання для приватних будинків",
    smallTitleEn: "Water supply website for private houses",
    portfolioDetailImg: [
      "/portfolio/krot/krotPh1.webp",
      "/portfolio/krot/krotPh2.webp",
      "/portfolio/krot/krotPh3.webp",
    ],

    technologies: [0, 1, 4, 5, 6, 7],
    titleEn: "KROT",
    titleUa: "КРОТ",
    descPrjctEn:
      "Water supply and sewage services for private houses. Provides services for drilling wells, cleaning existing boreholes, developing new solutions for laying pipes, septic tanks, and external structures for wells.",
    descPrjctUa:
      "Водопостачання то водовідведення для приватних будинків. Надає послуги з заглиблення скважин та колодязів, чистки наявних свердловин, розробка нових рішень з прокладання труб, септиків та завнішніх конструкцій для колодязя.",
    descSmallEn:
      "A portfolio website showcasing examples of work and a list of services, with the ability for contact.",
    descSmallUa: "Сайт-візитка з прикладами робит та переліком послуг і можливістю зворотного зв'язку.",
  },
  {
    id: 14,
    img: "/portfolio/transp/transpPh1.webp",
    typeEn: "Web-site",
    typeUa: "Веб-сайт",
    smallTitleUa: "Сайт транспортної компанії",
    smallTitleEn: "Website of a transportation company",
    portfolioDetailImg: [
      "/portfolio/transp/transpPh1.webp",
      "/portfolio/transp/transpPh2.webp",
      "/portfolio/transp/transpPh3.webp",
    ],

    technologies: [2, 3, 5, 6, 7, 8],
    titleEn: "Transporter",
    titleUa: "Транспортер",
    descPrjctEn:
      "The transportation company offers services for cargo transportation and rental of specialized equipment.",
    descPrjctUa: "Транспортна компанія надає послуги з перевезення вантажів та оренди спецтехніки.",
    descSmallEn: "Development of a website with a personal account for tracking the stage of cargo transportation.",
    descSmallUa: "Розробка веб-сайту з особистим кабінетом для відслідковування етапу перевезення вантажу.",
  },
];
