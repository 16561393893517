import "../../styles/Services.scss";
const AngularIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="50px" height="50px" className="ServicesIcon">
      <g
        fill="#485469"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
      >
        <g transform="scale(8,8)">
          <path
            d="M16,3.93555l-11.63867,4.23242l2.08789,15.67188l9.55078,5.30469l9.55078,-5.30664l2.08789,-15.66992zM16,6.06445l9.44336,3.43359l-1.74414,13.08008l-7.69922,4.27734l-7.69922,-4.27734l-1.74414,-13.08008zM16,8l-6,14h2l1.28906,-3h5.42188l1.28906,3h2zM16,12.55078l0.01953,0.05859l0.70117,1.75l1.12891,2.64063h-3.69922l1.12891,-2.64062l0.70117,-1.75z"
            className="ServicesIconFill"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default AngularIcon;
