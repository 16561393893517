import React, { useEffect, useRef, useState } from "react";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import { Modal } from "./Modal";
import TelegramBtnIcon from "../../assets/icons/TelegramBtnIcon";
import TelegramWhite from "../../assets/icons/TelegramWhite";
import WhatsappWhite from "../../assets/icons/WhatsappWhite";
import ViberWhite from "../../assets/icons/ViberWhite";
import { useTranslation } from "react-i18next";

const CallButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSidebar, setIsSidebar] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isHide, setIsHide] = useState(false);
  const message = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
    setIsHide(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setIsSidebar(false);
        setIsHide(false);
        if (message.current) {
          setTimeout(() => {
            if (message.current) message.current.classList.add("tgMessageShow");
          }, 500);
        }
      }
    };

    if (isSidebar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebar]);

  useEffect(() => {
    setTimeout(() => {
      if (message.current) {
        setTimeout(() => {
          if (message.current) message.current.classList.add("tgMessageShow");
        }, 500);
      }
    }, 500);
  }, [isSidebar]);

  return (
    <>
      {!isHide && (
        <div className="message" ref={message} onClick={() => setIsHide(true)}>
          {t("tgMessage1")}
        </div>
      )}
      <div ref={sidebarRef} className={`call-btn ${isSidebar ? "call_btn_open" : ""}`} onClick={toggleSidebar}>
        {isSidebar === true ? (
          <>
            <button className="sidebarButton">
              <a href="https://t.me/abcd_expert">
                <TelegramWhite />
              </a>
            </button>
            <button className="sidebarButton">
              <a href="https://wa.me/+380673694499">
                <WhatsappWhite />
              </a>
            </button>
            <button className="sidebarButton">
              <a href="viber://chat?number=%2B380673694499">
                <ViberWhite />
              </a>
            </button>
            <button className="sidebarButton" onClick={toggleModal}>
              <PhoneIcon />
            </button>
          </>
        ) : (
          <>
            <TelegramBtnIcon />
          </>
        )}
      </div>
      {showModal && (
        <div className="modalContent">
          <Modal toggleModal={toggleModal} typeFromBody={"Лендінг"} />
          <div className="modalBg" onClick={toggleModal}></div>
        </div>
      )}
    </>
  );
};

export default CallButton;
