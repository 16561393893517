import axios from "axios";

const baseUrl = "https://api.telegram.org/bot7082059517:AAH2ZXRH_1en8mqGp7pgfmCJJJdsleD-5JQ/";

export const sendMessage = async (message: string): Promise<void> => {
  const url: string = `${baseUrl}sendMessage?chat_id=-4221172560&text=${message}`;

  console.log(message);
  const response: Response = await fetch(url);

  console.log("response", response);
};

export const sendFile = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("document", file);
  const url = `${baseUrl}sendDocument?chat_id=-4221172560&text=${file}`;

  await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
