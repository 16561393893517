import "../../styles/Services.scss";
const NextIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,256,256" width="50px" height="50px" className="ServicesIcon">
      <g
        fill="#485469"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
      >
        <g transform="scale(5.33333,5.33333)">
          <path
            className="ServicesIconFill"
            d="M24,4c-11.028,0 -20,8.972 -20,20c0,11.028 8.972,20 20,20c11.028,0 20,-8.972 20,-20c0,-11.028 -8.972,-20 -20,-20zM24,7c9.374,0 17,7.626 17,17c0,5.852 -2.97233,11.02008 -7.48633,14.08008l-13.77148,-20.41992c-0.367,-0.547 -1.05464,-0.7857 -1.68164,-0.5957c-0.63,0.194 -1.06055,0.77655 -1.06055,1.43555v13c0,0.828 0.672,1.5 1.5,1.5c0.828,0 1.5,-0.672 1.5,-1.5v-8.09375l10.87695,16.12695c-2.106,0.935 -4.42895,1.4668 -6.87695,1.4668c-9.374,0 -17,-7.626 -17,-17c0,-9.374 7.626,-17 17,-17zM29.5,15c-0.828,0 -1.5,0.672 -1.5,1.5v8.48633l3,4.30273v-12.78906c0,-0.828 -0.672,-1.5 -1.5,-1.5z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default NextIcon;
