import "./styles/App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import PortfolioDetail from "./components/PortfolioDetail";
import Footer from "./components/Footer";
import CallButton from "./components/ui/CallButton";
import { useEffect, useState } from "react";
import ScrollToHashElement from "./components/ScrollToHashElement";
import { Provider } from "react-redux";
import store from "./store/store";
import PreLoader from "./components/ui/PreLoader";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset / 2.5);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <div className="App">
      {isLoading && (
        <div className="Loader">
          <div className="Loader_body">
            <img src={process.env.PUBLIC_URL + "loaderRed.svg"} alt="" className="imgLoaderBefore" />
            <PreLoader></PreLoader>
            <img src={process.env.PUBLIC_URL + "loaderBlue.svg"} alt="" className="imgLoaderAfter" />
          </div>
        </div>
      )}
      <div className="content">
        <div className="BodyAVAVA">
          <img
            src={process.env.PUBLIC_URL + "background.svg"}
            alt=""
            className="bgimageTest"
            style={{ transform: `translateX(${-scrollPosition}px)` }}
          />
        </div>
        <Provider store={store}>
          <Header />
          <CallButton></CallButton>
          <ScrollToHashElement />
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/projects/:id" element={<PortfolioDetail />} />
          </Routes>
          <Footer />
        </Provider>
      </div>
    </div>
  );
}

export default App;
