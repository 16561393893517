import "../styles/PortfolioModal.scss";
import "swiper/css";
import "swiper/css/scrollbar";
import Layout from "./Layout";
import ReactIcon from "../assets/icons/ReactIcon";
import NextIcon from "../assets/icons/NextIcon";
import AngularIcon from "../assets/icons/AngularIcon";
import JSIcon from "../assets/icons/JSIcon";
import TSIcon from "../assets/icons/TSIcon";
import NodeIcon from "../assets/icons/NodeIcon";
import ExpressIcon from "../assets/icons/ExpressIcon";
import SQLIcon from "../assets/icons/SQLIcon";
import AWSIcon from "../assets/icons/AWSIcon";
import FirebaseIcon from "../assets/icons/FirebaseIcon";

import portfolio from "../data/portfolio.json";

import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import { Meteors } from "./ui/meteors";
import { PortfolioItem } from "../data/portfolio.interface";

const PortfolioDetail = () => {
  let allPortfolio = portfolio.portfolio;
  const [showAllPortfolio, setShowAllPortfolio] = useState<any[]>([]);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [countOfSlides, setCountOfSlides] = useState(3);
  const project = location.state?.projectData;
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (allPortfolio && id) {
      let arr = allPortfolio.filter((item) => item.id !== parseInt(id));
      arr.sort(() => Math.random() - 0.5);
      setShowAllPortfolio(arr);
    }
  }, [allPortfolio, id]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 950) {
        setCountOfSlides(3);
      }
      if (window.innerWidth <= 950 && window.innerWidth >= 600) {
        setCountOfSlides(2);
      }
      if (window.innerWidth <= 600) {
        setCountOfSlides(1);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [allPortfolio]);

  useEffect(() => {
    let width = window.innerWidth;
    if (width >= 950) {
      setCountOfSlides(3);
    }
    if (width <= 950 && width >= 600) {
      setCountOfSlides(2);
    }
    if (width <= 600) {
      setCountOfSlides(1);
    }
  }, []);

  return (
    <div className="portfolio-detail">
      <Layout
        title={i18n.language === "en" ? project.titleEn : project.titleUa}
        type={i18n.language === "en" ? project.typeEn : project.typeUa}
      >
        <div className="PortfolioAbout">
          <Swiper
            scrollbar={{
              hide: true,
            }}
            grabCursor={true}
            modules={[Scrollbar]}
            className="PortfolioSlider"
            resistanceRatio={0}
          >
            <SwiperSlide className="PortfolioCurrSlide">
              <img src={process.env.PUBLIC_URL + project.portfolioDetailImg[0]} alt="" />
            </SwiperSlide>
            <SwiperSlide className="PortfolioCurrSlide">
              <img src={process.env.PUBLIC_URL + project.portfolioDetailImg[1]} alt="" />
            </SwiperSlide>
            <SwiperSlide className="PortfolioCurrSlide">
              <img src={process.env.PUBLIC_URL + project.portfolioDetailImg[2]} alt="" />
            </SwiperSlide>
          </Swiper>
          <div className="PortfolioRightSection">
            <h1 className="PortfolioRightTitle">{i18n.language === "en" ? project.titleEn : project.titleUa}</h1>
            <p className="PortfolioRightText">{i18n.language === "en" ? project.descPrjctEn : project.descPrjctUa}</p>
            <h1 className="PortfolioRightSmallTitle">{t("portfolioSmallTitle")}</h1>
            <p className="PortfolioRightText">{i18n.language === "en" ? project.descSmallEn : project.descSmallUa}</p>
            {project.link && (
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                <button className="PortfolioLink">{t("portfolioButton")}</button>
              </a>
            )}
          </div>
        </div>
        <div className="PortfolioTechnologies">
          <h1 className="PortfolioTechnologiesTitle">{t("PortfolioTechnologiesTitle")}</h1>
          <div className="PortfolioTechnologiesBody">
            {project.technologies.map((el: any, index: any) => (
              <div className="PortfolioTechnologiesCard" key={index}>
                <div className=" w-full relative max-w-xs">
                  <div className="absolute inset-0 h-full w-full bg-gradient-to-r from-blue-500 to-teal-500 transform scale-[0.90] bg-red-500 rounded-full blur-lg" />
                  <div className="PortfolioTechnologiesCardData">
                    {cardData[el].icon}
                    <h1 className="font-bold text-xl text-white mb-4 relative z-50">{cardData[el].name}</h1>
                    <Meteors number={3} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="PortfolioSame">
          <h1 className="PortfolioSameTitle">{t("PortfolioSameTitle")}</h1>
          <Swiper
            slidesPerView={countOfSlides}
            spaceBetween={10}
            freeMode={true}
            resistanceRatio={0}
            modules={[Scrollbar]}
            grabCursor={true}
            className="PortfolioSameSlider"
          >
            {showAllPortfolio.map((item: PortfolioItem, index: any) => (
              <SwiperSlide className="PortfolioSameCurrSlide" key={index}>
                <Link to={`/projects/${item.id}`} state={{ projectData: item }}>
                  <img src={process.env.PUBLIC_URL + item.img} alt="" />
                  <p className="PortfolioSameCurrText">{item.titleEn}</p>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Layout>
    </div>
  );
};

export default PortfolioDetail;

const cardData = [
  {
    id: 20,
    name: "React",
    icon: <ReactIcon />,
  },
  {
    id: 21,
    name: "NextJS",
    icon: <NextIcon />,
  },
  {
    id: 22,
    name: "Angular",
    icon: <AngularIcon />,
  },
  {
    id: 23,
    name: "JavaScript",
    icon: <JSIcon />,
  },
  {
    id: 24,
    name: "TypeScript",
    icon: <TSIcon />,
  },
  {
    id: 25,
    name: "NodeJS",
    icon: <NodeIcon />,
  },
  {
    id: 26,
    name: "Express JS",
    icon: <ExpressIcon />,
  },
  {
    id: 27,
    name: "MySQL",
    icon: <SQLIcon />,
  },
  {
    id: 28,
    name: "AWS",
    icon: <AWSIcon />,
  },
  {
    id: 29,
    name: "Firebase",
    icon: <FirebaseIcon />,
  },
];
