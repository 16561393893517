import { createStore } from "redux";

const initialState = {
  data: "Initial data",
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
