const InstWhite = () => {
  return (
        <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M12 24C5.383 24 0 18.617 0 12C0 5.383 5.383 0 12 0C18.617 0 24 5.383 24 12C24 18.617 18.617 24 12 24ZM12 1C5.935 1 1 5.935 1 12C1 18.065 5.935 23 12 23C18.065 23 23 18.065 23 12C23 5.935 18.065 1 12 1Z" 
                fill="white"
            />
            <g clipPath="url(#clip0_191_145)">
                <path 
                    d="M14.9167 18.4167H9.08334C8.15509 18.4167 7.26485 18.048 6.60847 17.3916C5.95209 16.7352 5.58334 15.845 5.58334 14.9167V9.08337C5.58334 8.15512 5.95209 7.26488 6.60847 6.6085C7.26485 5.95212 8.15509 5.58337 9.08334 5.58337H14.9167C15.8449 5.58337 16.7352 5.95212 17.3916 6.6085C18.0479 7.26488 18.4167 8.15512 18.4167 9.08337V14.9167C18.4167 15.845 18.0479 16.7352 17.3916 17.3916C16.7352 18.048 15.8449 18.4167 14.9167 18.4167ZM9.08334 6.75004C8.4645 6.75004 7.87101 6.99587 7.43343 7.43346C6.99584 7.87104 6.75001 8.46454 6.75001 9.08337V14.9167C6.75001 15.5355 6.99584 16.129 7.43343 16.5666C7.87101 17.0042 8.4645 17.25 9.08334 17.25H14.9167C15.5355 17.25 16.129 17.0042 16.5666 16.5666C17.0042 16.129 17.25 15.5355 17.25 14.9167V9.08337C17.25 8.46454 17.0042 7.87104 16.5666 7.43346C16.129 6.99587 15.5355 6.75004 14.9167 6.75004H9.08334Z" 
                    fill="white"
                />
                <path 
                    d="M12 15.5C11.3078 15.5 10.6311 15.2947 10.0555 14.9101C9.47993 14.5256 9.03133 13.9789 8.76642 13.3394C8.50152 12.6999 8.4322 11.9961 8.56725 11.3172C8.7023 10.6383 9.03564 10.0146 9.52513 9.52513C10.0146 9.03564 10.6383 8.7023 11.3172 8.56725C11.9961 8.4322 12.6999 8.50152 13.3394 8.76642C13.9789 9.03133 14.5256 9.47993 14.9101 10.0555C15.2947 10.6311 15.5 11.3078 15.5 12C15.5 12.9283 15.1313 13.8185 14.4749 14.4749C13.8185 15.1313 12.9283 15.5 12 15.5ZM12 9.66667C11.5385 9.66667 11.0874 9.80352 10.7037 10.0599C10.32 10.3163 10.0209 10.6807 9.84428 11.1071C9.66768 11.5334 9.62147 12.0026 9.7115 12.4552C9.80153 12.9078 10.0238 13.3236 10.3501 13.6499C10.6764 13.9762 11.0922 14.1985 11.5448 14.2885C11.9974 14.3785 12.4666 14.3323 12.8929 14.1557C13.3193 13.9791 13.6837 13.68 13.9401 13.2963C14.1965 12.9126 14.3333 12.4615 14.3333 12C14.3333 11.3812 14.0875 10.7877 13.6499 10.3501C13.2123 9.9125 12.6188 9.66667 12 9.66667Z" 
                    fill="white"
                />
                <path 
                    d="M15.5 8.50004C15.8222 8.50004 16.0833 8.23887 16.0833 7.91671C16.0833 7.59454 15.8222 7.33337 15.5 7.33337C15.1778 7.33337 14.9167 7.59454 14.9167 7.91671C14.9167 8.23887 15.1778 8.50004 15.5 8.50004Z" 
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_191_145">
                    <rect 
                        width="14" 
                        height="14" 
                        fill="white" 
                        transform="translate(5 5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default InstWhite;