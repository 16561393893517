"use client";
import "../../styles/Modal.scss";
import React, { useState } from "react";
import { cn } from "../../utils/cn";
import { useTranslation } from "react-i18next";
import { sendMessage } from "../../api/telegram";
import { message } from "antd";
import { useMask } from "@react-input/mask";
import CrossIcon from "../../assets/icons/CrossIcon";
import { getFormSubmissionCount, incrementFormSubmissionCount } from "../../utils/localStorageHelper";

const messages = [
  {
    messageSuccessUk: "Ваша заявка успішно надіслана!",
    messageSuccessEn: "Your message was sent successfully!",
  },
  {
    messageErrorUk: "Заповніть усі поля!",
    messageErrorEn: "Fill in all fields!",
  },
  {
    messageLimitUk: "Ви досягли максимальної кількості заявок.",
    messageLimitEn: "You have reached the maximum number of submissions.",
  },
];

export function Modal({ toggleModal, typeFromBody }: any) {
  const { t, i18n } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const inputRef = useMask({ mask: "(__)-___-__-_____", replacement: { _: /\d/ } });

  const closeModal = () => {
    toggleModal(false);
  };

  const messageSuccess = () => {
    messageApi.open({
      type: "success",
      content: i18n.language === "uk" ? messages[0].messageSuccessUk : messages[0].messageSuccessEn,
      className: "Message",
    });
  };

  const messageError = () => {
    messageApi.open({
      type: "error",
      content: i18n.language === "uk" ? messages[1].messageErrorUk : messages[1].messageErrorEn,
      className: "Message",
    });
  };

  //////////// start

  const messageLimit = () => {
    messageApi.open({
      type: "warning",
      content: i18n.language === "uk" ? messages[2].messageLimitUk : messages[2].messageLimitEn,
      className: "Message",
    });
  };

  /////////////// end

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    type: `${typeFromBody}`,
    text: "",
    messType: "Зателефонували",
  });

  const handleChange = (e: any) => {
    const { name, value, files, type } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
    } else if (type === "radio") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const submissionCount = getFormSubmissionCount();

    // if (submissionCount >= 3) {
    //   messageLimit();
    //   return;
    // }

    try {
      if (formValues.name && formValues.phone && formValues.text) {
        let cleanedPhoneNumber = "+(38) 0" + formValues.phone.replace(/[^\d]/g, "");
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${cleanedPhoneNumber}  %0A- Тип сайту: ${formValues.type} %0A- Клієнт хоче щоб йому: ${formValues.messType} %0A- Текст: ${formValues.text}`;

        await sendMessage(message);
        messageSuccess();

        incrementFormSubmissionCount();
      } else {
        messageError();
      }
    } catch (e) {
      console.log("Error");
    } finally {
      closeModal();
    }
  };

  return (
    <div className="max-w-lg mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input modalWindow PhoneModal">
      <div className="CrossButtonSection">
        <button className="CloseModalButton" onClick={closeModal}>
          <CrossIcon />
        </button>
      </div>
      <h2 className="font-bold text-xl text-neutral-200">{t("modalTitle")}</h2>
      {/* <p className="text-sm max-w-sm mt-2 text-neutral-300 smallDescription">{t("modalDesc")}</p> */}

      <form className="my-8 ModalForm" onSubmit={handleSubmit}>
        <LabelInputContainer>
          <input
            className="ModalPhoneInput"
            id="name"
            placeholder={t("footerInputName")}
            type="text"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            required
          />
        </LabelInputContainer>
        <LabelInputContainer>
          <div className="ModalInputBody">
            <span>+380</span>
            <input
              className="ModalPhoneInput"
              placeholder="(99)-999-99-99"
              ref={inputRef}
              type="tel"
              name="phone"
              required
              value={formValues.phone}
              onChange={handleChange}
            />
          </div>
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <input
            className="ModalDescriptionInput"
            id="projectDescription"
            placeholder={t("inputDescription")}
            type="text"
            name="text"
            value={formValues.text}
            onChange={handleChange}
            required
          />
        </LabelInputContainer>

        <div className="radio-input">
          <label className="label">
            <input
              type="radio"
              id="Лендінг"
              checked={formValues.type === "Лендінг"}
              name="type"
              value="Лендінг"
              onChange={handleChange}
            />
            <p className="text">{i18n.language === "en" ? "Landing" : "Лендінг"}</p>
          </label>
          <label className="label">
            <input
              type="radio"
              id="Корпоративний сайт"
              checked={formValues.type === "Корпоративний сайт"}
              name="type"
              value="Корпоративний сайт"
              onChange={handleChange}
            />
            <p className="text">{i18n.language === "en" ? "Corporate website" : "Корпоративний сайт"}</p>
          </label>
          <label className="label">
            <input
              type="radio"
              id="Інтернет-магазин"
              checked={formValues.type === "Інтернет-магазин"}
              name="type"
              value="Інтернет-магазин"
              onChange={handleChange}
            />
            <p className="text">{i18n.language === "en" ? "Online-store" : "Інтернет-магазин"}</p>
          </label>
          <label className="label">
            <input
              type="radio"
              id="Маркетплейс"
              checked={formValues.type === "Маркетплейс"}
              name="type"
              value="Маркетплейс"
              onChange={handleChange}
            />
            <p className="text">{i18n.language === "en" ? "Marketplace" : "Маркетплейс"}</p>
          </label>
          <label className="label">
            <input
              type="radio"
              id="Мобільний застосунок"
              checked={formValues.type === "Мобільний застосунок"}
              name="type"
              value="Мобільний застосунок"
              onChange={handleChange}
            />
            <p className="text">{i18n.language === "en" ? "Mobile application" : "Мобільний застосунок"}</p>
          </label>
        </div>

        <div className="radio-mesg-input">
          <label>
            <input
              value="Зателефонували"
              name="messType"
              id="Зателефонували"
              type="radio"
              checked={formValues.messType === "Зателефонували"}
              onChange={handleChange}
            />
            <span>{i18n.language === "en" ? "Call me" : "Телефонувати мені"}</span>
          </label>
          <label>
            <input
              value="Написали в TG"
              name="messType"
              id="Написали в TG"
              type="radio"
              checked={formValues.messType === "Написали в TG"}
              onChange={handleChange}
            />
            <span>{i18n.language === "en" ? "Write to me in TG" : "Писати мені в TG"}</span>
          </label>
          <span className="selection"></span>
        </div>

        <button
          className="bg-gradient-to-br relative group/btn from-white to-white block bg-white w-full text-black rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset] PhoneModalButton"
          type="submit"
        >
          {t("footerBtn")} &rarr;
          <BottomGradient />
        </button>
      </form>
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={cn("flex flex-col space-y-2 w-full", className)}>{children}</div>;
};
